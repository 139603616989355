.active-link {
    display: block;
    height: 5px;
    width: 5px;
    margin: auto;
    border-radius: 50%;
    background-color: #3491FF;
}

.btn-group__item {
    cursor: pointer;
    box-shadow: inset 0px 0px 0px -15px rebeccapurple;
    text-transform: capitalize;
}

.btn-group__item:hover,
.btn-group__item:focus {
    outline: none;
}

.nav-item{
    cursor: pointer !important;
}
.nav-item .active{
    position:relative;
}
.nav-item .active::after{
    content: "";
    padding: 3px;
    /* background-color: #3491FF; */
    position: absolute;
    bottom: -10px;
    left: 49%;
    border-radius: 50px;
}

#mobile-menu-2{
    margin: 0px auto;
    justify-content: center;
    align-items: center;
}
#hamburger-btn{
    display: none;
}
.sidebar-navigations .MuiTypography-root, .sidebar-navigations .MuiListItemIcon-root
,.sidebar-navigations .link-tag, .link-tag, .icons
{
    color: #fff !important;
}


@media only screen and (max-width: 767px) {
 
    
#hamburger-btn{
    display: block;
}

#mobile-menu-2{
    margin: 0px 0;
    justify-content: right;
    align-items: right;
}
   
}


