.garidents-svgImg {
    font-size: 30px;
    font-weight: 800;
    background-color: #FFFFFF;
    border: 10px solid #F4FAFF;
    height: 120px;
    width: 118px;
    border-radius: 50%;
    top: -5rem;
}

::-webkit-scrollbar {
    width: 8px;
    /* display: none; */
}

#scroll::-webkit-scrollbar{
    display: none;
}

#scrollbar::-webkit-scrollbar {
    /* width: 8px !important; */
    display: none;
}


/* Track */

 ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 8px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #3491FF;
    border-radius: 8px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #3491FF;
}