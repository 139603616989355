
.single-invoice-table table tr td{
    border: 1px solid;
}
.body-table  tr  td{
}

.bg-tr td{
    padding: 15px 5px;
    
}

.no-border-table td{    
    border: none !important;
}

.text-align-top{
    vertical-align: top;
}

.address-table{
    margin-left: auto;
}


// .pdf-page {
//     display: block;
//     margin: 0px auto;
//     width: calc(100 / 23 * 21vw);
//     // height: calc(100 / 23 * 29.7vw);
//     margin: calc(100 / 23 * 1vw) auto;
//     border: 1px #D3D3D3 solid;
//     border-radius: 5px;
//     background: white;
//     box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
//   }
  
//   .pdf-page  {
//     // margin: calc(100 / 23 * 1vw);
//     // width: calc(100 / 23 * 19vw);
//     width: 990px;
//     margin: 0px auto;
//     // height: calc(100 / 23 * 27.7vw);
//     outline: 0cm #FAFAFA solid;
//   }
  
//   @page {
//     size: A4;
//     margin: 0;
//   }
  
//   @media print {
//     .pdf-page {
//       margin: 0;
//       border: initial;
//       border-radius: initial;
//       width: initial;
//       min-height: initial;
//       box-shadow: initial;
//       background: initial;
//       page-break-after: always;
//     }
//   }


.text-right{
    text-align: right;
}