.stepper-triangle {
    left: -12px !important;
    top: 21px;
}

.stepper-circle {
    left: 18px;
    top: 26px;
}


/* .custom-line {
    position: relative;
    left: 80px;
} */