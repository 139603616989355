.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bottom-arrow-section::before,
.bottom-arrow-section::after {
    width: calc(51% - 100px);
}

#product-slider-home .slick-prev {
    left: -21px;
}

#product-slider-home .slick-prev {
    left: -21px;
}

#product-slider-home .slick-next {
    right: -3px;
}

.dx-link.dx-link-edit {
    background-color: #DDE7F7;
    padding: 4px !important;
    font-size: 1.3rem !important;
    color: #3491FF;
    width: auto !important;
    height: auto !important;
}

.dx-link.dx-link-delete {
    background-color: #FFE5E7;
    padding: 4px;
    font-size: 2.5rem;
    color: #C74950;
    padding: 4px !important;
    font-size: 1.3rem !important;
    width: auto !important;
    height: auto !important;
}


/* .dx-link.dx-link-edit {
    border-radius: 4px;
    text-decoration: none;
    background: #3491FF;
    color: #fff;
    padding: 2px 14px;
}

a.dx-link.dx-link-delete {
    border: 1px solid #3491FF;
    text-decoration: none;
    border-radius: 4px;
    color: #3491FF;
    padding: 2px 14px;
    background: #ffffff;
}

a.dx-link.dx-link-save {
    border-radius: 4px;
    text-decoration: none;
    background: #3491FF;
    color: #fff;
    padding: 2px 14px;
}

a.dx-link.dx-link-cancel {
    border: 1px solid #3491FF;
    text-decoration: none;
    border-radius: 4px;
    color: #3491FF;
    padding: 2px 14px;
    background: #ffffff;
} */

#circle-slider-section .slick-slide .single-slider {
    min-height: 500px;
}

#trade-download-section .slick-dots {
    display: block !important;
    bottom: 36px;
    width: 75%;
    right: 53px;
}

.slick-prev {
    width: 50px;
    left: -45px;
    top: 48%;
    position: absolute;
    height: 50px;
    background: #ffff !important;
    border: 1px solid #3491FF;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14), 0 6px 6px rgba(0, 0, 0, 0.15);
}

.slick-next {
    width: 50px;
    right: -45px;
    top: 48%;
    position: absolute;
    height: 50px;
    background: #ffff !important;
    border: 1px solid #3491FF;
    z-index: 1;
    border-radius: 50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.14), 0 6px 6px rgba(0, 0, 0, 0.15);
}

textarea.w-full.h-full {
    resize: none;
    overflow: hidden;
}

.border-technical {
    /* border: 1px solid; */
    /* max-width: fit-content; */
    width: 98%;
    background: linear-gradient(to right, #0FCE7E, #0080F6);
}

.side-line {
    top: 50%;
    left: 27%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
    .side-line {
        left: 20%;
    }
}

.side-line1 {
    top: 50%;
    left: 73.8%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
    .side-line1 {
        width: 66%;
        left: 73.8%;
    }
}

@media screen and (max-width: 1024px) {
    .bottom-arrow-section::before,
    .bottom-arrow-section::after {
        width: calc(56% - 100px);
    }
}

@media screen and (max-width: 768px) {
    .bottom-arrow-section::before,
    .bottom-arrow-section::after {
        width: calc(58% - 100px);
    }
}

.gst-card .width-class {
    width: 15rem !important;
}

.helpheading {
    align-items: center;
    text-align: center;
    justify-content: center;
}

.helpmodel {
    margin-top: -2px;
    margin-bottom: 8px;
    width: 100%;
    background: #fff;
    border-radius: 3px;
    display: flex;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.32);
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    padding: 2px;
    padding-left: 12px;
    transition: 0.3s;
}

.helpmodel:hover {
    color: #3491FF;
    cursor: pointer;
}

.first {
    margin-bottom: 35px;
    width: 350px;
    background: #fff;
    border-radius: 3px;
    border-bottom: 6px solid rgb(68, 0, 255);
    border-left: 3px solid rgb(68, 0, 255);
    display: flex;
    box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.32);
    cursor: pointer;
}

.css-1wnsr1i {
    padding-top: 15px !important;
}

.loading-pannel {
    position: absolute;
    top: 50%;
    left: 50%;
}

.position-loading {
    height: 200px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.rotate90 {
    transform: rotate(90deg);
    color: #0080F6;
}

.rotate180 {
    transform: rotate(270deg);
    color: #0080F6;
}

.slick-list {
    width: inherit;
}

.scrollbar {
    height: 130px !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-dropdown-menu {
    background-color: #0a2a4a;
    /* width: 205px; */
    border-radius: 5px;
}

.set-icon {
    position: absolute;
    right: 480px;
    top: 74px;
    /* box-shadow: -12px -11px 10px -10px rgba(227,227,227,1); */
}

.set-path {
    box-shadow: -12px -11px 10px -10px rgb(227 227 227);
    color: #ebebeb;
}

.ant-dropdown-menu-item {
    color: white;
    font-size: 18px;
}

.ant-dropdown-arrow::before {
    background-color: #0a2a4a !important;
}

.ant-dropdown-menu-item-active {
    background-color: none !important;
    color: black !important;
}

.ant-dropdown-show-arrow {
    top: 74px !important;
}

#main-download-slider .slick-prev::before {
    content: '\2039' !important;
    padding: 1px 11px;
    position: absolute;
    font-size: 62px;
    top: 0px;
    line-height: .4;
    left: 0;
    color: #0080F6;
}

#main-download-slider .slick-next:before {
    content: '\203a' !important;
    padding: 1px 11px;
    position: absolute;
    font-size: 62px;
    top: 0px;
    line-height: .4;
    left: 5px;
    color: #0080F6;
}

button.slick-arrow.slick-next {
    border: 1px solid #0080F6;
}

button.slick-arrow.slick-prev {
    border: 1px solid #0080F6;
}


/* .img-card{
    display: flex;
    justify-content: center;
    width: 100%;
} */


/* .btn-card{
    flex: 1;
} */

@media only screen and (max-width: 1024px) {
    .btn-card {
        flex: 1;
    }
}

@media only screen and (max-width: 1024px) {
    .img-card {
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .main-card {
        width: 100%;
        display: flex !important;
        justify-content: center !important;
    }
}

@media only screen and (max-width: 1024px) {
    .clear-space {
        margin-left: 0px !important;
    }
}

.word-wrap {
    word-break: break-word;
    text-transform: capitalize !important;
}

.k-fonttext {
    font-size: 5px !important;
}

.dx-text-content-alignment-left {
    text-transform: capitalize !important;
}

.dx-header-filter {
    color: aliceblue !important;
    /* margin-top: 15px; */
}

.dx-datagrid-group-opened {
    top: 20px !important;
}

.dx-datagrid-group-closed {
    top: 20px !important;
}

.Popup {
    display: flex;
    flex-wrap: wrap;
    max-height: 95%;
    overflow-y: auto;
    justify-content: space-around;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell {
    vertical-align: inherit;
}

td {
    vertical-align: middle !important;
}

td p {
    margin-block: 0px !important;
}

.rotate {
    transform: rotate(180deg);
}


/* .common{
    text-align: left;
    margin-left: 25px;
} */

.dx-datagrid .dx-header-filter {
    color: #4fff00 !important;
}

.dx-header-filter.dx-header-filter-empty {
    color: #fff !important;
}

.dx-header-row>td>.dx-datagrid-text-content:not(.dx-sort-indicator):not(.dx-header-filter-indicator) {
    text-transform: capitalize !important;
}

.ant-dropdown {
    position: fixed;
}

.border-top-style {
    border-top: 0px !important;
}

.border-bottom-style {
    border-bottom: 0px !important;
}

.padding-margin {
    padding: 0px !important;
    margin: 0px !important;
}


/* kendo-pdf-document td {
    font-weight: 800 !important;
} */

.center-style {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ant-dropdown-menu {
    padding: 0px !important;
}

.dx-datagrid-text-content {
    text-transform: capitalize !important;
}

.dx-column-indicators {
    margin-top: 10px;
}

.error-text {
    position: absolute;
    left: 173px;
    bottom: -83px;
}

.titlestyle {
    margin-top: 15px;
    justify-content: center;
}

.pagging {
    display: flex;
}

@media only screen and (max-width: 583px) {
    .pagging {
        flex-wrap: wrap !important;
    }
}

@media only screen and (max-width: 583px) {
    .tab-style {
        width: 100%;
    }
}

.card-style {
    display: flex;
}

@media only screen and (max-width: 874px) {
    .card-style {
        flex-wrap: wrap;
    }
}

.Account-style {
    width: 40%;
}

@media only screen and (max-width: 874px) {
    .Account-style {
        width: 100%;
    }
}

.gap-style {
    width: 20%;
}

@media only screen and (max-width: 874px) {
    .gap-style {
        width: 0.2%;
        margin-top: 10px;
    }
}

.card-font {
    font-size: 18px;
}

@media only screen and (max-width: 425px) {
    .card-font {
        font-size: 15px;
    }
}

.header-style{
    background: #3491ff;
    color: white;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.slider-btn{
    top: 36% !important;
    right: -1% !important;
}

@media only screen and (max-width: 425px) {
    .slider-btn{
        top: 38% !important;
        right: -10% !important;
    }
}

.trade-plus-btn-left{
    left: 2%;
    top: 52%;
}

@media only screen and (max-width: 425px) {
    .trade-plus-btn-left{
        left: 8%;
        top: 54%;
    }
}

.trade-plus-btn-right{
    right: 2%;
    top: 52%;
}

@media only screen and (max-width: 425px) {
    .trade-plus-btn-right{
        right: 8%;
        top: 54%;
    }
}
.card-padding{
    padding-right: 20px !important;
    width: 100%;
}

.btn-model-style{
    border: 1px solid;
    color: white;
    background: #3491ff;
    border-radius: 5px;
    width: 95px;
    height: 35px;
}



