.circle-text {
    position: relative;
    top: 27px;
    right: -29px;
    font-size: 30px;
    font-weight: 800;
    color: #FFFFFF;
}

.garident-circle {
    width: 100px;
    height: 100px;
}