$media-desktop: "only screen and (min-width : 1024px)";
$media-tablet: "only screen and (min-width : 768px)";
$media-mobile: "only screen and (min-width : 600px)";
$media-mobile-sm: "only screen and (min-width : 480px)";
.circle-effect {
    position: relative;
}

.slider-section {
    min-height: 500px;
    margin-bottom: 36px;
    .slick-next {
        @media only screen and (min-width: 768px) {
            width: 50px;
            right: 0px;
            top: 40%;
        }
    }
    .slick-prev {
        width: 50px;
        left: -1px;
        top: 40%;
    }
}

.cone {
    margin-top: -20px;
    height: 0px;
    width: 0px;
    border-right: 45px solid transparent;
    border-left: 45px solid transparent;
    border-top: 140px solid tan;
    z-index: 1;
    border-radius: 50%;
}

.crescent-moon-1 {
    position: relative;
    width: 100px;
    height: 100px;
}

.crescent-moon-1::after {
    content: "";
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    box-shadow: -26px 0px 0px 20px #ffd700;
    transform: rotate(-24deg);
    border-radius: 0% 100% 100% 0% / 100% 0% 100% 0%;
}

.circleThing-right,
.circleThing-left,
.circleThing-center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    @media only screen and (min-width: 768px) {
        top: 5rem;
    }
}

.circleThing-center {
    @media only screen and (min-width: 768px) {
        top: 3rem;
    }
}

div.circleThing-right::before {
    content: "";
    position: absolute;
    background: #24C69D;
    transform: rotate(0deg);
    width: 203px;
    height: 206px;
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    border-bottom-right-radius: 180px;
    border-bottom-left-radius: 0%;
    right: 30px;
    @media only screen and (min-width: 768px) {
        right: -16%;
        width: 70%;
        height: 72%;
        top: 93%;
    }
}

div.circleThing-left::before {
    content: "";
    position: absolute;
    background: #24C69D;
    transform: rotate(356deg);
    width: 215px;
    height: 217px;
    border-top-left-radius: 1%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 83%;
    right: 210px;
    top: 81.8%;
    @media only screen and (min-width: 768px) {
        right: 45%;
        width: 70%;
        height: 72%;
        top: 95%;
    }
}

div.circleThing-center::before {
    content: "";
    position: absolute;
    background: #24C69D;
    width: 400px;
    height: 200px;
    border-top-left-radius: 300px;
    border-top-right-radius: 300px;
    bottom: 114px;
    @media only screen and (min-width: 768px) {
        width: 278px;
        height: 119px;
        bottom: 99px;
    }
}

.circle-effect {
    background: #fff;
    position: relative;
    padding: 20px 30px;
    margin: 5px;
    display: inline-block;
    font-size: 30px;
    width: 300px;
    margin: 0px auto;
}

.circle-effect-right::after,
.circleThing-right::after,
.circleThing-center::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    padding: 10px;
    background: linear-gradient(92deg, #0080F6, #0FCE7E);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    box-shadow: 0px 2px 16px rgba(61, 61, 61, 0.06);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
    width: 300px;
    height: 300px;
    z-index: 100;
    @media only screen and (min-width: 768px) {
        width: 220px;
        height: 210px;
    }
}

.custom-h-w {
    width: 300px;
    height: 300px;
    z-index: 100;
    position: relative;
    @media only screen and (min-width: 768px) {
        width: 211px;
        height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 34px;
    }
}

.circle-effect-right .custom-h-w,
.circle-effect-left .custom-h-w,
div.circleThing-right::before,
div.circleThing-left::before,
.circleThing-right::after,
.circleThing-left::after {
    bottom: -150px;
}

.circle-effect-right .custom-h-w,
.circle-effect-left .custom-h-w {
    bottom: -165px;
}

.circle-effect-left .custom-h-w {
    right: -1px;
}

.slick-slide .single-slider {
    min-height: auto;
    display: flex !important;
}

#trade-download-section {
    .slick-slide .single-slider {
        min-height: fit-content;
    }
}

// .slick-active .single-slider{
//     display:flex  !important;
// }
.slick-slide .singled-slider {
    min-height: auto;
}