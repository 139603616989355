.ellipse-img {
    position: absolute;
    top: -8%;
    right: 115px;
}

.text-one {
    position: absolute;
    top: 23px;
    left: 23px;

}