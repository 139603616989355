@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}

@media (min-width: 1380px) {
    .container {
        max-width: 1536px;
    }
}

#contract-section .center-box {
    width: 250px;
    height: 250px;
}

@media (max-width: 1024px) {
    .container.flex.flex-wrap.justify-between.items-center.mx-auto.mb-15.px-10.py-12 {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
    .container.px-20.py-6.mx-auto {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
    .container.flex.flex-wrap.px-20.py-10.mx-auto {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    #contract-section .center-box {
        width: 200px;
        height: 200px;
    }
    #contract-section .main-title {
        font-size: 17px;
        width: 100%;
        text-align: center;
    }
    #container.flex.flex-wrap.px-20.py-10.mx-auto {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .center-box.relative.justify-center.items-center.flex.mb-20.rounded-full.w-200.h-200.mx-auto.mt-20.bg-gradient-to-r.p-\[1\.5px\].to-\[\#56a8f4\].via-\[\#05C876\].from-\[\#bbd6eb\] {
        margin-top: 10rem;
    }
    .center-box.relative.justify-center.items-center.flex.mb-20.rounded-full.lg\:w-200.lg\:h-200.md\:h-100.md\:w-100.mx-auto.mt-20.bg-gradient-to-r.p-\[1\.5px\].to-\[\#56a8f4\].via-\[\#05C876\].from-\[\#bbd6eb\] {
        margin-top: 10rem;
    }
    .top.flex.mb-20.rounded-xl.w-100.h-30.mx-auto.mt-10.bg-gradient-to-r.p-\[1\.5px\].to-\[\#56a8f4\].via-\[\#0FCE7E\].from-\[\#bbd6eb\] {
        margin-top: 5rem;
    }
    .top.flex.rounded-xl.w-100.h-30.mx-auto.mt-10.bg-gradient-to-r.p-\[1\.5px\].to-\[\#56a8f4\].via-\[\#05C876\].from-\[\#bbd6eb\] {
        margin-top: 4rem;
    }
    .text-\[1rem\] {
        margin-left: 5px;
        font-size: 14px !important;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.-left-5.top-1.mr-4.text-opacity-20.text-\[\#3491FF\] {
        top: 9px;
        left: 52px;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.bottom-10.mr-4.text-opacity-20.text-\[\#3491FF\] {
        left: 49px;
        top: 31px;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.top-7.mr-4.text-opacity-20.text-\[\#3491FF\] {
        left: 52px;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.-left-4.top-1.mr-4.text-opacity-20.text-\[\#3491FF\] {
        left: 52px;
    }
    #deliveries-section .slick-next {
        left: 61% !important;
    }
    #deliveries-section .slick-prev {
        left: 31% !important;
    }
    .after\:w-\[90\%\]::after {
        content: var(--tw-content);
        width: 84% !important;
    }
    p.text-\[\#808D9A\].ml-\[3rem\].pl-\[1rem\].text-\[1rem\].font-medium.p-4.bg-\[\#ffffff\] {
        margin-left: 59px;
    }
    p.text-\[\#808D9A\].text-\[1rem\].font-medium {
        margin-left: 32px;
    }
    p.text-\[\#808D9A\].text-\[1rem\].font-medium.ml-\[4rem\].p-4.bg-\[\#ffffff\] {
        margin-left: 4rem;
    }
    p.text-\[\#808D9A\].text-\[1rem\].font-medium.ml-\[2rem\].p-4.bg-\[\#ffffff\] {
        margin-left: 2rem;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[33rem\].relative.end-point {
        border-top-width: 3px;
    }
    p.w-\[60\%\].text-\[\#808D9A\].text-\[18px\].ml-\[2rem\] {
        font-size: 14px;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[28rem\].relative.end-point {
        width: 25rem !important;
        border-top-width: 3px !important;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[24rem\].relative.end-point {
        border-top-width: 3px !important;
        width: 19rem !important;
    }
    .flex.justify-center.w-\[20rem\].h-40.bg-slate-300.p-5.md\:bg-\[\#FFFFFF\].items-center.drop-shadow-md.rounded-xl {
        width: 11rem !important;
    }
    .p-12.lg\:w-1\/3.md\:w-1\/2.w-full {
        margin-top: 23px;
        padding: 0px;
    }
    .p-5.lg\:w-1\/3.md\:w-1\/2.w-full {
        padding: 0px;
    }
    /* .flex.items-left.relative {
        margin-left: 25px;
    } */
    /* .flex-grow.text-center.p-6 {
        margin-left: 25px;
    } */
    .gradient-circle {
        position: relative;
        left: -93px !important;
    }
    p.text-\[\#808D9A\].lg\:text-\[1rem\].text-\[0\.75rem\].font-small.lg\:ml-\[5rem\].ml-\[4rem\].lg\:p-8.p-6.bg-\[\#F4FAFF\] {
        margin-left: 0rem;
    }
    .right-box.z-10.mt-\[\.5rem\] {
        margin-top: -48px;
    }
    .content.relative.top-\[2\.5rem\].left-\[7rem\].w-\[65\%\] {
        left: 5rem;
    }
    p.text-\[\#808D9A\].pt-4.text-\[1rem\] {
        padding-top: 2px;
    }
    #timeline-section .card .desc {
        max-width: 21rem;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[33rem\].relative.end-point.sm\:hidden.md\:block.lg\:block {
        border-top-width: 3px;
    }
}

@media (max-width: 768px) {
    .p-12.lg\:w-1\/3.md\:w-1\/2.w-full {
        padding: 0rem;
        padding-left: 38px;
    }
    .p-5.lg\:w-1\/3.md\:w-1\/2.w-full {
        padding: 0px;
        padding-left: 17px;
    }
    .slick-slider {
        width: 621px !important;
    }
    #contract-section .center-box {
        width: 150px;
        height: 150px;
    }
    #contract-section .main-title {
        font-size: 17px;
        width: 100%;
        text-align: center;
    }
    #container.flex.flex-wrap.px-20.py-10.mx-auto {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }
    .center-box.relative.justify-center.items-center.flex.mb-20.rounded-full.lg\:w-200.lg\:h-200.md\:h-100.md\:w-100.mx-auto.mt-20.bg-gradient-to-r.p-\[1\.5px\].to-\[\#56a8f4\].via-\[\#05C876\].from-\[\#bbd6eb\] {
        margin-top: 10rem;
    }
    .text-\[1rem\] {
        margin-left: 5px;
        font-size: 14px !important;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.-left-5.top-1.mr-4.text-opacity-20.text-\[\#3491FF\] {
        top: 2px;
        left: 31px;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.bottom-10.mr-4.text-opacity-20.text-\[\#3491FF\] {
        left: 52px;
        top: 28px;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.top-7.mr-4.text-opacity-20.text-\[\#3491FF\] {
        left: 53px;
    }
    span.text-3xl.lg\:text-5xl.font-bold.sm\:text-3xl.md\:text-3xl.absolute.-left-4.top-1.mr-4.text-opacity-20.text-\[\#3491FF\] {
        left: 32px;
    }
    #deliveries-section .slick-next {
        left: 63% !important;
    }
    #deliveries-section .slick-prev {
        left: 30% !important;
    }
    .after\:w-\[90\%\]::after {
        content: var(--tw-content);
        width: 84% !important;
    }
    .container.flex.flex-wrap.px-20.py-10.mx-auto {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
    p.text-\[\#808D9A\].text-\[1rem\].font-medium.ml-8.p-4 {
        margin-left: 3rem;
    }
    p.text-\[\#808D9A\].text-\[1rem\].font-medium.ml-\[4rem\].p-4.bg-\[\#ffffff\] {
        margin-left: 4rem;
    }
    p.text-\[\#808D9A\].text-\[1rem\].font-medium.ml-\[2rem\].p-4.bg-\[\#ffffff\] {
        margin-left: 2rem;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[33rem\].relative.end-point {
        border-top-width: 3px;
    }
    p.w-\[60\%\].text-\[\#808D9A\].text-\[18px\].ml-\[2rem\] {
        font-size: 14px;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[28rem\].relative.end-point {
        width: 25rem !important;
        border-top-width: 3px !important;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[24rem\].relative.end-point {
        border-top-width: 3px !important;
        width: 19rem !important;
    }
    .flex.justify-center.w-\[20rem\].h-40.bg-slate-300.p-5.md\:bg-\[\#FFFFFF\].items-center.drop-shadow-md.rounded-xl {
        width: 13rem !important;
    }
    #contract-section .left-side-arrow>img,
    #contract-section .right-side-arrow>img {
        position: absolute;
        width: 121px;
    }
    #contract-section .left-side-arrow .left-bottom {
        top: 118px !important;
        right: -9px !important;
    }
    #contract-section .left-side-arrow>img,
    #contract-section .right-side-arrow>img {
        position: absolute;
        width: 151px !important;
    }
    .gradient-circle {
        position: relative;
        left: -72px !important;
    }
    .center-box.relative.flex.justify-center.items-center.mb-20.rounded-full.w-200.h-200.mx-auto.mt-20.bg-gradient-to-r.p-\[1\.5px\].to-\[\#56a8f4\].via-\[\#0FCE7E\].from-\[\#bbd6eb\] {
        margin-top: 10rem;
    }
    .flex-grow.ml-11.w-\[75\%\] {
        margin-left: 0rem;
    }
    .flex-grow.ml-8.w-\[75\%\] {
        margin-left: 0rem;
    }
    .content.relative.top-\[2\.5rem\].left-\[7rem\].w-\[65\%\] {
        left: 70px;
    }
    p.tetx-\[400\].text-lg.md\:text-md.text-center.text-\[\#808D9A\] {
        font-size: 13px;
    }
    p.tetx-\[400\].text-.md\:text-md.lg.text-center.text-\[\#808D9A\] {
        font-size: 13px;
    }
    span.border-dashed.border-t-\[5px\].border-\[\#0080F6\].w-\[19rem\].relative.end-point.sm\:hidden.md\:block.lg\:block {
        border-top-width: 3px;
        width: 10rem;
    }
    .top.flex.mb-2.rounded-xl.w-100.h-30.mx-auto.mt-10.bg-gradient-to-r.p-\[1\.5px\].to-\[\#56a8f4\].via-\[\#0FCE7E\].from-\[\#bbd6eb\] {
        margin-top: 4rem;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-left {
        font-size: 16px !important;
    }
    tr.dx-row.dx-data-row.dx-column-lines {
        font-size: 14px !important;
    }
    .dx-datagrid-text-content.dx-text-content-alignment-right {
        font-size: 16px !important;
    }
    .dx-datagrid-text-content {
        font-size: 16px !important;
    }
}

#contract-section .bottom {
    margin-top: 200px;
}

#contract-section .trade-web-charges .bottom-right {
    margin-top: 128px;
}

#contract-section .main-title {
    width: 100%;
    text-align: center;
}

#contract-section .left-side-arrow {
    position: absolute;
    left: -115px;
}

#contract-section .right-side-arrow {
    position: absolute;
    right: -115px;
}

#contract-section .left-side-arrow>img,
#contract-section .right-side-arrow>img {
    position: absolute;
    width: 163px;
}

#contract-section .left-side-arrow .left-top {
    position: relative;
    width: 163px;
    left: 9px;
    top: -93px;
}

#contract-section .left-side-arrow .left-bottom {
    top: 118px;
    right: -16px;
}

#contract-section .right-side-arrow .right-top {
    position: relative;
    top: -104px;
}

#contract-section .right-side-arrow .right-bottom {
    top: 111px;
    left: -12px;
}

#deliveries-section .slick-list {
    padding-top: 68px;
}

#deliveries-section .slick-prev,
#deliveries-section .slick-next {
    width: 50px;
    left: 43%;
    top: auto;
    bottom: -29%;
    position: absolute;
    height: 50px;
}

#deliveries-section .slick-next {
    left: 53%;
}

#deliveries-section .slick-slider {
    max-width: 100%;
}

#deliveries-section .slick-next::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: blue;
}

.arrow-section {
    display: none;
}

.end-point::after {
    content: '';
    position: absolute;
    top: -12px;
    padding: 10px;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(15, 206, 126, 1) 0%, rgba(0, 212, 255, 1) 100%);
    right: -5px;
}

#trade-download-section .slick-dots {
    display: block !important;
    bottom: 12px;
    width: 75%;
}

.download-sliders .slider-card {
    /* width: 370px; */
    width: 375px;
}

.download-sliders .slick-dots {
    bottom: 20px;
}


/* .dx-command-edit > .dx-link-edit{
    display: none;
    position: relative;
} */


/* .dx-command-edit > .dx-link-edit::after{
    position: absolute;
    content: '\270E';
    color: blueviolet;
} */


/* .before\:w-\[26rem\]::before {
    content: var(--tw-content);
    width: 59rem !important;
}

.before\:w-\[26rem\]::before {
    content: var(--tw-content);
    width: 59rem !important;
} */

#brockrage-facility-slider .slick-next {
    right: 20px;
    top: 41%;
}

.dx-row.dx-column-lines.dx-header-row {
    background: #3491ff !important;
    color: #fff;
}

#trade-row .dx-row.dx-column-lines.dx-header-row{
    background: none;
}

.dx-datagrid-text-content.dx-text-content-alignment-left {
    font-size: 16px;
    line-height: 1.5rem;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: uppercase;
    font-weight: 600;
}

.dx-datagrid-text-content.dx-text-content-alignment-right {
    font-size: 16px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: uppercase;
    font-weight: 600;
}

.dx-datagrid-text-content {
    font-weight: 600;
    font-size: 16px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    text-transform: uppercase;
}

tr.dx-row.dx-data-row.dx-column-lines {
    font-size: 18px;
    font-weight: 500;
}
.MuiBox-root{
    padding: 15px !important;
}

.full-width-modal.MuiModal-root .MuiBox-root {
    width: 90%;
}

.dx-datagrid-text-content.dx-text-content-alignment-left {
    font-size: 18px;
}

.dx-datagrid-rowsview .dx-master-detail-row > .dx-master-detail-cell{
    padding: 5px !important;
}

.dx-datagrid-text-content.dx-text-content-alignment-right {
    font-size: 18px;
}

.dx-datagrid-text-content.dx-text-content-alignment-left {
    /* margin-left: 41px; */
}

.dx-datagrid-text-content.dx-text-content-alignment-left.dx-sort-indicator {
    margin-left: 0;
}

.dx-datagrid-text-content {
    font-size: 18px;
}

.odd-color tr:nth-child(even) {
    /*class="bg-gray-50";*/
    background: #FFFFFF;
}

.odd-color tr:nth-child(odd) {
    /*class="bg-gray-50";*/
    background: #E2EFFF;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #3491FF !important;
}

.test-back:nth-child(odd) {
    background: #F4FAFF !important;
}

.test-back:nth-child(even) {
    background: #FFFFFF !important;
}

span.lazy-load-image-background {
    display: flex !important;
    justify-content: center;
    width: 100%;
}

.MuiDataGrid-columnHeaderTitleContainer {
    text-align: right !important;
    justify-content: center;
}

.MuiDataGrid-columnHeaderTitleContainer {
    background: #3491FF;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-menuIcon {
    width: 0px !important;
    /* font-size: 20px; */
    margin-right: 0px !important;
    /* display: -webkit-box; */
    /* display: -webkit-flex; */
    display: -ms-flexbox;
    display: none !important;
    /* -webkit-align-items: center; */
    /* -webkit-box-align: center; */
    -ms-flex-align: center;
    /* align-items: center; */
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-iconSeparator {
    /* color: red; */
    display: none !important;
}

.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeader--sortable {
    padding: 0 !important;
    cursor: pointer;
}

.loginstyle {
    display: flex;
    justify-content: center;
}

.download-sliders>div {}

.download-sliders>div {
    min-height: auto;
}

@media only screen and (max-width: 768px) {
    .download-sliders {
        justify-content: center !important;
    }
    .download-sliders>div {
        min-height: 500px;
        width: 250px;
        height: auto;
    }
}

@media only screen and (max-width: 648px) {
    .download-sliders {
        justify-content: center !important;
    }
    .download-sliders>div {
        margin-top: 25px;
        min-height: 550px;
        width: 250px;
        height: auto;
    }
}


/* .test-back{
    display: inline-block;
} */


/* 
.download-msg{
    
} */

.mobile-slider .slick-slide>div {
    min-height: 360px;
}

@media only screen and (max-width: 600px) {
    #contract-section .left-side-arrow>img,
    #contract-section .right-side-arrow>img {
        display: none;
    }
    #contract-section .bottom {
        margin-top: 20px;
    }
}

.customHtml table{
    display: table !important;
    border-collapse: separate !important;
    box-sizing: border-box !important;
    text-indent: initial !important;
    border-spacing: 2px !important;
    border-color: gray !important;
    border-top-width: 1px !important;
    border-bottom-width: 1px !important;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
    
}
.customHtml table tr, .customHtml th, .customHtml table td{
   
    padding: 0.5rem;
    border: 1px solid;
}