.garident-svgImg {
    left: auto;
    font-size: 30px;
    font-weight: 800;
    background-color: #FFFFFF;
    border: 10px solid #F4FAFF;
    height: 120px;
    width: 118px;
    border-radius: 50%;
    top: -5rem;
}