.stepper-triangle {
    left: -13px;
    top: 28px;
}

.stepper-circle {
    left: 18px;
    top: 26px;
}

.reports-list {
    &:last-child {
        border-left: none !important;
        border-color: none !important;
        border: none !important;
        border-left-width: 0px !important;
        color: red !important;
    }
}